@import '../../../media-query';
@import '../../../styles/constants/colors.scss';
.anticipation-simulation__container--loading {
    padding-top: 70px;
    background-color: $alabaster;
    height: 100vh;
    .gfLNMF {
        .iepmoa {
            background-color: $organization_primary_focus;
        }
    }
}

.anticipation-simulation__container--error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $alabaster;
    border: solid $mercury 1px;
    border-radius: 8px;
    height: 100vh;

    .anticipation-simulation__error-message {
        font-family: 'Catamaran', sans-serif;
        font-size: 1.25rem;
        color: $scorpion;
        text-align: center;
        line-height: 20px;
        margin-bottom: 30px;
    }
    .button__style--back .MuiButton-contained:hover,
    .button__style--back .MuiButton-contained {
        max-width: 220px;
    }
}

.container__simulation {
    position: relative;
    display: flex;
    margin-top: 0px;
    min-height: calc(100vh - 165px);
    flex-direction: column;
    justify-content: center;

    @include responsive(s) {
        margin-top: 115px;
        padding-bottom: 50px;
    }

    &--content {
        background-color: $alabaster;
        position: relative;   
    }

    .simulation--text {
        display: flex;
        font-family: Frutiger;
        font-size: 0.875rem;
        font-weight: 300;
        margin-bottom: 15px;
        margin-top: 30px;
        align-items: center;
        span {
            font-size: 1rem;
            font-weight: 400;
        }
    }
    &--title {
        font-family: Frutiger;
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 20px;
    }
    &--subtitle {
        font-family: Frutiger;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 5px;
        margin-top: 25px;
    }
    &--cards {
        .MuiPaper-elevation1 {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
            border-radius: 6px;
        }
    }
}
