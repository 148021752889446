@import '../../../../../styles/constants/colors.scss';
@import '../../../../../media-query';
@import '../card-service.scss';

.card-payrollloan-recent-propose__container {
    border: 1px solid #f5f5f5;
    margin: 0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), -4px 0px 0px #3f578b;
    width: 100%;
    border-radius: 4px;
    display: flex;
    font-family: 'Frutiger';

    @include responsive(s) {
        height: 240px;
    }

    @include responsive(ss) {
        height: 250px;
    }

    &--edit{
        padding: 0 20px;
        display: flex;
        align-items: center;
    }

    &--info {
        width: 100%;
        padding: 30px 20px 20px 27px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ffffff;

        h3 {
            font-size: 18px;
            font-weight: 500;
        }
    }

    &-status {
        display: inline-flex;
        font-size: 12px;
        align-items: center;
        white-space: nowrap;
        justify-content: flex-start;
        height: 24px;
        font-weight: 500;
        border-radius: 5px;
        padding: 5px 6px;
        margin-bottom: 2px;
        font-family: 'Frutiger';

        &.HISTORY {
            background-color: #6acff1;
        }

        &.UNKNOWN_MANAGER {
            background-color: #f2c466;
        }

        &.MANAGER_REVIEW {
            background-color: #f2c466;
        }

        &.HUMAN_RESOURCES_REVIEW {
            background-color: #f2c466;
        }
        &.OPERATIONAL_REVIEW {
            background-color: #f2c466;
        }
        &.NEED_CHANGE {
            background-color: #f2c466;
        }
        &.INVALID_BANK_ACCOUNT {
            background-color: #f2c466;
        }
        &.APPROVED {
            background-color: #6acff1;
        }
        &.WAITING_SIGNATURE {
            background-color: #6acff1;
        }
        &.SIGNED {
            background-color: #b8d759;
        }
        &.CANCELLED {
            background-color: #ff7978;
        }
        &.RECUSED {
            background-color: #ff7978;
        }
    }
    &--credit-value {
        padding: 0px 15px 5px 0;
        font-size: 28px;
        font-family: 'Frutiger';
        font-style: normal;
        font-weight: 500;
        color: #3f578b;
    }

    &--title {
        display: flex;
        justify-content: space-between;
        padding-right: 13px;

        h3 {
            margin: 0;
        }
        &__icon {
            color: #3f578b;
            margin-right: 11px;

            &.disabled {
                path {
                    fill: #bac0c2;
                }
            }
        }
    }

    &--date {
        font-family: 'Frutiger-light';
        color: #888888;
        line-height: 21px;
        margin: 25px 0 18px 0;
        font-size: 18px;
        max-width: 290px;
        letter-spacing: 1.1px;

        @media only screen and (max-width: 1265px) {
            padding-bottom: 12px;
        }
    }

    &--footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
    }

    &--credit-available {
        width: 230px;

        h4 {
            font-family: 'Frutiger-light';
            color: #888888;
            font-size: 15px;
            margin-bottom: 0 !important;
            width: 230px;
        }

        @media only screen and (max-width: 960px) {
            width: 65%;
        }

        @media only screen and (max-width: 538px) {
            width: 60%;
        }
    }

    &--button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
