@import '../../../media-query';
@import '../../../styles/constants/colors.scss';


.page-mobile-header {
    background: $organization_secondary;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100vw;
    z-index: 3;
    display: flex;
    color: $organization_secundary_link;
    display: flex;
    height: 74px;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    gap: 66px;
    flex-shrink: 0;

    &--menu {
        content: url('../../../images/menu.svg');
        display: flex;
        align-items: center;
        max-width: 110px;
        
        @include responsive(ss) {
            padding: 0;
        }

        @include responsive(s) {
            max-width: 90px;
        }
    }

        &--logo {
            content: url('../../../images/custom_organization/white_logo.svg');
            display: flex;
            align-items: center;
            max-width: 110px;

            @include responsive(ss) {
                padding: 0;
            }

            @include responsive(s) {
                max-width: 90px;
            }
        }

        &--menu-itens {
            display: flex;
            height: 100%;
            align-items: center;

            a {
                padding: 0px 25px;
            }
        }

        &--link {
            display: inline-block;
            color: $organization_secundary_link;
            font-size: 0.875rem;
            font-family: 'Frutiger';
            cursor: pointer;
            text-align: center;
            padding: 0 5px;
            text-decoration: none;
            white-space: nowrap;

            @media only screen and (max-width: 536px) {
                font-size: 0.675rem;
            }
        }

        &--link:hover {
            color: $organization_secundary_link_hover;
            transition: 0.2s;
        }

        &--company {
            border-left: solid 1px $organization_secundary_border;
            border-right: solid 1px $organization_secundary_border;
            height: 100%;
            width: 380px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .company--buttom {
                background: $organization_secundary_button;
                width: 134px;
                height: 19px;
                border: none;
                border-radius: 4px;
                font-family: 'Frutiger';
                font-weight: 500;
                font-size: 0.563rem;
                letter-spacing: 1.5px;
                color: white;
            }

            .company--buttom:hover {
                background: $organization_secundary_button_focus;
            }

            .gfLNMF {
                height: 18px;
                width: 18px;
                .iepmoa {
                    height: 9px;
                    width: 9px;
                    background-color: $white;
                }
            }
        }

        &--notification {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    
}

.mobile-navbar {
    display: flex;
    flex-direction: column;
    width: 240px;
    height: 100vh;
    background: $organization_secondary;
    position: fixed;
    z-index: 100;
    padding: 24px;
    padding-top: 80px;
    transition: all 3000ms ease-in-out 0s;
    gap: 24px;
    .page-header--menu-list-item{
      width: 100%;
    }
    .page-header--menu-list-item-button{
      width: 100%;
    }

    .page-header--menu-list {
      display: inline-flex;
      align-items: center;
      margin: 0;
      padding: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 24px;
    }

    &--close {
        content: url('../../../images/close.svg');
        display: flex;
        align-items: center;
        max-width: 110px;
        position: absolute;
        top: 22px;
        left: 16px;
    }

    .mobile-navbar--profile {
      align-self: center;
      margin: 15px;
    }
      .mobile-navbar--link {
          display: inline-block;
          color: $organization_secundary_link;
          font-size: 0.875rem;
          font-family: 'Frutiger';
          cursor: pointer;
          text-align: center;
          padding: 0 5px;
          text-decoration: none;
          white-space: nowrap;

          @media only screen and (max-width: 536px) {
              font-size: 0.675rem;
          }
      }

      .mobile-navbar--link:hover {
          color: $organization_secundary_link_hover;
          transition: 0.2s;
      }
}

.page-header {
    background: $organization_secondary;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100vw;
    z-index: 3;
    display: flex;
    justify-content: center;
    padding: 0 5vw;

    &--logo {
        content: url('../../../images/custom_organization/white_logo.svg');
        margin-right: 25px;
        max-width: 150px;
    }

    > div {
        max-width: 1115px;
        width: 100%;
    }


    .header-employee {
        display: flex;
        justify-content: flex-start;
        height: 70px;
        font-family: 'Frutiger';

        &--logo {
            content: url('../../../images/custom_organization/white_logo.svg');
            display: flex;
            align-items: center;
            max-width: 110px;

            @include responsive(ss) {
                padding: 0;
            }

            @include responsive(s) {
                max-width: 90px;
            }
        }

        &--menu-itens {
            display: flex;
            height: 100%;
            align-items: center;

            a {
                padding: 0px 25px;
            }
        }

        &--link {
            display: inline-block;
            color: $organization_secundary_link;
            font-size: 0.875rem;
            font-family: 'Frutiger';
            cursor: pointer;
            text-align: center;
            padding: 0 5px;
            text-decoration: none;
            white-space: nowrap;

            @media only screen and (max-width: 536px) {
                font-size: 0.675rem;
            }
        }

        &--link:hover {
            color: $organization_secundary_link_hover;
            transition: 0.2s;
        }

        &--company {
            border-left: solid 1px $organization_secundary_border;
            border-right: solid 1px $organization_secundary_border;
            height: 100%;
            width: 380px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .company--buttom {
                background: $organization_secundary_button;
                width: 134px;
                height: 19px;
                border: none;
                border-radius: 4px;
                font-family: 'Frutiger';
                font-weight: 500;
                font-size: 0.563rem;
                letter-spacing: 1.5px;
                color: white;
            }

            .company--buttom:hover {
                background: $organization_secundary_button_focus;
            }

            .gfLNMF {
                height: 18px;
                width: 18px;
                .iepmoa {
                    height: 9px;
                    width: 9px;
                    background-color: $white;
                }
            }
        }

        &--profile {
            border-left: solid 1px $organization_secundary_border;
            border-right: solid 1px $organization_secundary_border;
            width: 180px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            @include responsive(s) {
                border-left: solid 1px $organization_secundary_border;
            }
        }

        &--notification {
            margin-right: 20px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &--company-text {
            font-family: 'Frutiger';
            color: #fafafa;
            font-size: 0.75rem;
            white-space: nowrap;
            overflow: hidden;
            max-width: 80%;
            text-overflow: ellipsis;
        }
    }

    @include responsive(ss) {
        .col-md-3 {
            display: none;
        }
        .col-md-2:first-child {
            display: none;
        }
    }
}

.page-header--items {
    padding: 0px 15px;
    width: 100%;
    display: flex;
}

.page-header--menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 74px;
    width: 100%;

    a,
    span {
        cursor: pointer;
        user-select: none;
    }

    &-list {
        display: inline-flex;
        align-items: center;
        margin: 0;
        padding: 0;
        height: 100%;

        &-item {
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            list-style: none;
            height: 60%;
            white-space: nowrap;
            margin: 0 5px;
            position: relative;

            &:hover {
                background-color: $organization_menu_hover;
            }

            &.active {
                background: $organization_menu_selected;

                span,
                svg {
                    color: white;
                }
            }

            &-button {
                border-radius: 5px;
                display: flex;
                justify-content: center;
                height: 100%;
                width: max-content;
                padding: 12px 20px;

                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                }

                &.active {
                    background: $organization_menu_selected;

                    span,
                    svg {
                        color: white;
                    }
                }

                > span {
                    color: $organization_secundary_link;
                    font-size: 14px;
                    font-weight: normal;
                }

                .fa-user-circle,
                .fa-user-lock {
                    color: $organization_secundary_link;
                    margin-right: 9px;
                }

                .fa-user-circle {
                    font-size: 22px;
                }

                .fa-user-lock {
                    font-size: 16px;
                    margin-top: 2px;
                }
            }
        }
    }
}