@import '../../../../styles/custom_organization/colors.scss';

.base-read-only-field {
    margin: 10px 0px;
    
    .MuiFilledInput-input {
        background: #f5f5f5;
        border-radius: 10px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #000000;
        text-align: center;
        padding-top: 34px;
    }

    .text__field.MuiFormControl-root.text__has-value {
        background: #f5f5f5;
        border: none;
    }

    .text__field.MuiFormControl-root .MuiFormLabel-root {
        width: 250px;
        color: #888888;
        font-size: 13px;
        left: 50%;
        transform: translate(-50%, 25%);
    }

    .MuiSvgIcon-root{
        font-size: 1.2rem;
        color: $organization_primary;
    }
}
