@import '../../styles/constants/colors.scss';
@import '../../media-query';

.main__employee-borrower {
    padding-top: 60px;
    height: 100vh;

    @include responsive(s) {
        height: 100%;
    }
}

.container__employee-borrower {
    flex-grow: 1;
    margin-top: 65px;
    text-align: 'center';
    font-family: 'Frutiger';

    &--loading {
        position: absolute;
        top: 320px;
        left: 350px;
    }
}

.error__employee-borrower {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 54vh;
    width: 780px;
    justify-content: center;

    @include responsive(s) {
        padding-left: 0px;
        width: 100%;
        text-align: center;
        padding: 0px 15px;
    }

    .error-message {
        font-family: 'Catamaran', sans-serif;
        font-size: 1.25rem;
        color: $scorpion;
        line-height: 20px;
        margin-bottom: 30px;
    }
    .button__style--back .MuiButton-contained:hover,
    .button__style--back .MuiButton-contained {
        max-width: 220px;
    }
}
