@import '../../media-query';
@import '../../styles/constants/colors.scss';
@import '../../styles/constants/fonts.scss';

.body__first-access {
    margin-top: 60px;
    flex-direction: column;
    align-items: center;
    font-family: 'Frutiger';
    @include responsive(s) {
        margin-top: 10px;
    }

    .body__password--buttons {
        display: flex;
        flex-wrap: initial;
        margin-bottom: 30px;
        @include responsive(s) {
            padding-top: 10px;
            margin-bottom: 5px;
            flex-wrap: nowrap;
        }
        @include responsive(ss) {
            padding-top: 10px;
            margin-bottom: -14px;
            flex-wrap: nowrap;
        }
    }

    &--title {
        margin: auto;
        font-size: 1.375rem;
        text-align: center;
        margin-bottom: 48px;
        line-height: 1.3;
        @include responsive(s) {
            margin-bottom: 15px;
        }

        span {
            font-weight: 500;
        }
    }

    .form__style {
      gap: 14px;
      display: flex;
      flex-direction: column;
    }

    .form__style,
    .container__button--actions {
        @include responsive(s) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .first-access--button:hover {
        background: $organization_primary_focus;
    }

    .container__button--actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 50px;
        align-items: center;
        @include responsive(s) {
            margin-top: 10px;
        }

        .button__style--back,
        .button__style--action {
          width: 100%;
          button {
            max-width: 100%;
          }
        }
    }
    .red_border {
      border: red 1px solid !important;
    }
}

.styled_error {
  color: var(--Red, #DB6968);
  font-family: Frutiger 45 Light;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  margin-top: 16px;
  letter-spacing: 0.5px;
}
