@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-Black.ttf);
    font-weight: 700;
}

@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-BlackItalic.ttf);
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-Bold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-BoldItalic.ttf);
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-MediumItalic.ttf);
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-Italic.ttf);
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-Light.ttf);
    font-weight: 300;
}

@font-face {
    font-family: 'Raleway';
    src: url(./fonts/Raleway-LightItalic.ttf);
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Frutiger';
    src: url(./fonts/Frutiger_bold.ttf);
    font-weight: 800;
}

@font-face {
    font-family: 'Frutiger-italic';
    src: url(./fonts/Frutiger_Italic.ttf);
}

@font-face {
    font-family: 'Frutiger';
    src: url(./fonts/Frutiger_semibold.ttf);
    font-weight: 500;
}

@font-face {
    font-family: 'Frutiger';
    src: url(./fonts/Frutiger_regular.ttf);
}

@font-face {
    font-family: 'Frutiger-light';
    src: url(./fonts/Frutiger_light.ttf);
    font-weight: 300;
}

@font-face {
    font-family: 'Frutiger-55';
    src: url('./fonts/Frutiger_55.ttf');
}

@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?28ai4b');
    src: url('fonts/icomoon.eot?28ai4b#iefix') format('embedded-opentype'), url('fonts/icomoon.ttf?28ai4b') format('truetype'),
        url('fonts/icomoon.woff?28ai4b') format('woff'), url('fonts/icomoon.svg?28ai4b#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-check2:before {
    content: '\e902';
    color: #9bc731;
    font-size: 28px;
    cursor: pointer;
}

.icon-check:before {
    content: '\e903';
    color: #b2b7bc;
    font-size: 28px;
    cursor: pointer;
}

.icon-flechas2:before {
    content: '\e900';
    color: #3aadd1;
    cursor: pointer;
}

.icon-flechas1:before {
    content: '\e901';
    color: #3aadd1;
    cursor: pointer;
}

.icon-flechas:before {
    content: '\e904';
    color: #3aadd1;
    cursor: pointer;
}
