@import '../../../../../styles/constants/colors.scss';

.document-file-input {
    margin: 15px 0px;
    font-family: Frutiger;

    &__label {
        display: flex;
        flex-direction: column;

        &--title {
            color: $organization_secondary;
            font-size: 15px;
        }

        &--description {
            color: #a5a8aa;
            font-size: 13px;
        }
    }

    &__file {
        cursor: pointer;
        color: $organization_primary;

        &--placeholder {
            border-bottom: 1px solid $organization_primary;
            font-size: 15px;
        }

        &--error-message {
            text-align: left;
            font-size: 0.75rem;
            width: 100%;
            color: #f44336;
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            font-weight: 400;
            line-height: 1.66;
            letter-spacing: 0.03333em;
        }

        &--selected {
            width: 100%;
            background-color: #f5f5f5;
            border-radius: 6px;
            color: $organization_primary;
            font-size: 13px;
            padding: 8px;
            margin: 5px 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            padding-right: 30px;

            &-value {
                padding: 0px 10px;
                cursor: pointer;

                &:hover {
                    color: $organization_primary_focus;
                }
            }

            &-file-icon {
                transform: rotate(220deg);
            }

            &-button-cancel {
                position: absolute;
                right: 5px;
                color: #d8d8d8;
                cursor: pointer;

                &:hover {
                    color: #ff4d6d;
                }
            }
        }
    }
}
