@import '../../media-query';
@import '../../styles/constants/colors.scss';

.text__field__currency.MuiFormControl-root {
    border-style: solid;
    border-color: $ice_blue;
    border: none;
    border-width: 2px;
    max-width: 335px;

    .MuiOutlinedInput-notchedOutline {
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 0%;
        padding-bottom: 10px;
    }

    .MuiFilledInput-root {
        color: $greyish_brown;
        border-radius: 0;
    }

    &.text__field__currency--focused {
        .MuiFilledInput-root {
            color: $black;
        }
    }

    &.text__field__currency--focused,
    &.text__has-value {
        border-color: $brown_grey_two;
        background-color: $white_two;
        transition: 1s;

        .MuiFilledInput-root {
            transition: 1s;
            background-color: $white_two;
        }

        .MuiFormHelperText-root.MuiFormHelperText-contained {
            transition: 1s;
            border-color: $brown_grey_two;
        }
    }

    &.text__field__currency--valid {
        transition: 1s;
        border-top-color: $gross_green;
    }

    &.text__field__currency--has-error {
        transition: 1s;
        font-size: 12px;
        border-top-color: $punch;
    }

    .MuiFilledInput-underline:after {
        border-bottom: transparent;
        top: 0;
        bottom: unset;
        margin: auto;
        width: 99%;
    }

    .MuiFilledInput-underline:before {
        display: none;
    }

    .MuiFormLabel-root,
    .MuiFormLabel-root.Mui-focused {
        width: 250px;
        font-family: 'Frutiger';
        font-weight: 300;
        font-size: 13px;
        letter-spacing: 0.541667px;
        line-height: 15px;
        color: #7b7b7b;
        text-align: center;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .text__field__currency--valid-adornment {
        .MuiSvgIcon-root {
            right: 10px;
            bottom: 10px;
            position: absolute;
            color: $gross_green;
        }
    }

    .MuiOutlinedInput-input {
        padding: 20px 15px 5px 10px;
        text-align: center;
        font-size: 27px;
        font-family: 'Frutiger';
        font-style: normal;
        font-weight: 500;
        color: $organization_primary;
    }
    .MuiFormHelperText-root.MuiFormHelperText-contained {
        color: $greyish_brown;
        border-style: solid;
        border-width: 0px;
        border-top-width: 1px;
        border-color: $ice_blue;
        font-size: 1.875rem;
        padding: 12px 25px 12px 10px;
        font-family: Frutiger;
        letter-spacing: 0.5px;
        line-height: 1.3;
        margin: 0px;
        background-color: $white_two;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        &.Mui-error {
            transition: 1s;
            color: $punch;
            font-size: 0.875rem;
        }
    }
}
