@import '../../../../../../../../media-query';
@import '../../../../../../../../styles/constants/colors.scss';

.card-simulate-summary {
    padding: 15px 20px 0px 20px;
    background: #3f578b;
    margin-top: 15px;
    margin-left: 10px;
    border-radius: 8px;
    width: 255px;
    color: white;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: 100%;

    @include responsive(s) {
        width: 100%;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    &.disabled {
        cursor: default;
    }

    &--first__container {
        margin: 10px 0px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    &--item {
        margin: 10px 0px;
        display: flex;
        flex-direction: column;

        @include responsive(s) {
          width: 100%;
        }

        &:last-child {
            border-top: 1px solid $organization_secundary_link;;  
            padding-top: 15px;
        }

        &__label {
            color: #d8d8d8;
            font-size: 14px;   
        }

        &__value {
            color: #FFFFFF;
            font-size: 18px;

            h2 {
                font-size: 25px;
            }
        }
    }

}
