@import '../../../../../media-query';
@import '../../../../../styles/custom_organization/colors.scss';

.header__simulate-card-detail {
    text-align: left;
    margin-left: 150px;
    display: flex;
    flex-direction: column;

    @include responsive(s) {
        padding-left: 0px;
        margin-left: 0px;
        align-items: center;
    }

    &--title {
        font-size: 30px;
        line-height: 24px;
        padding: 20px 0px;
        font-weight: 500;
    }

    &--subtitle {
        font-size: 25px;
        line-height: 28px;
        color: $organization_secondary;
        padding: 5px 0px;
        font-weight: 300;
    }

    &--description {
        font-size: 15px;
        line-height: 16px;
        color: #a5a8aa;
        font-weight: 300;
    }
}

.card__simulate-card-detail {
    max-width: 410px;
    margin-left: 150px;
    margin-top: 15px;
    height: 100%;
    margin-bottom: 80px;

    @include responsive(s) {
        margin-left: 0px;
        margin: 20px auto;
        margin-bottom: 150px;
    }

    &--read-only {
        width: 100%;
        background-color: #f5f5f5;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 20px;

        strong {
            font-weight: 600;
        }

        .MuiGrid-item {
            padding: 2px 8px;
        }
    }
}
