@import '../../media-query';
@import '../../styles/constants/colors.scss';
@import '../../styles/constants/fonts.scss';

.card-component {
    .button--close {
        content: url('./../../images/fechar.svg');
        position: absolute;
        top: 10px;
        right: 25px;
        @include responsive(s) {
            content: url('./../../images/fechar-mob.svg');
        }
    }

    .MuiCard-root {
        border-radius: 8px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        background: #ffffff;
    }

    .MuiPaper-root {
        background-color: white;
    }

    .card__identify--text {
        font-size: 0.813rem;
        color: $greyish_brown;
        font-family: 'Frutiger';
        font-weight: 300;
        @include responsive(s) {
            font-size: 0.875rem;
            line-height: 18px;
            padding-right: 0;
        }

        p {
            margin: unset;
            padding-top: 20px;
        }
    }
}
