@import '../../../media-query';
@import '../../../styles/constants/colors.scss';
@import '../../../styles/constants/fonts.scss';

.button__style--fourth {
    .MuiButton-contained {
        background-color: $organization_primary;
        box-shadow: unset;
    }

    .MuiButton-contained:hover {
        background-color: $organization_primary_focus;
        box-shadow: unset;
    }

    .MuiButton-label {
        justify-content: center;
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        color: #fff;
        font-size: 0.875rem;
        font-family: 'Frutiger';
        font-weight: 500;
        letter-spacing: 1.5px;
        border-radius: 10px;
        width: 100%;
        max-width: 170px;
        min-width: 170px;
        min-height: 45px;
        max-height: 45px;
        outline: none;
        @include responsive(s) {
            max-width: 130px;
            min-width: 130px;
        }
    }

    .MuiButton-contained.Mui-disabled {
        background: $organization_primary_disabled;
        cursor: not-allowed;

        span {
            color: white;
        }
    }
}
