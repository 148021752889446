@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';
@import '../../../../styles/constants/fonts.scss';

.step-images {
    &--actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;
        font-family: 'Frutiger';
        font-weight: 300;
        letter-spacing: 0.7px;
        letter-spacing: 0.67;
    }

    .step-next {
        content: url('../../../../images/Path.svg');
        display: inline;
    }

    .step-close {
        content: url('../../../../images/Path2.svg');
        display: inline;
    }

    .step-check {
        content: url('../../../../images/step-check.svg');
        display: inline;
    }
    .step-edit {
        display: inline;
        font-size: 0.85rem;
        font-family: 'Frutiger';
        color: $pumpkin;
        letter-spacing: 0.2px;
    }
}

.responsive-step--text:nth-child(1) {
    .step-edit {
        padding-left: 100px;
        @include responsive(ss) {
            padding-left: 50px;
        }
    }
}

.responsive-step--text:nth-child(2) {
    .step-edit {
        padding-left: 167px;
        @include responsive(ss) {
            padding-left: 117px;
        }
    }
}

.responsive-step--text:nth-child(3) {
    .step-edit {
        padding-left: 143px;
        @include responsive(ss) {
            padding-left: 93px;
        }
    }
}
