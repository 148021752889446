@import '../../../media-query';
@import '../../../styles/constants/colors.scss';
@import '../../../styles/constants/fonts.scss';

.button__style {
    .MuiButton-contained {
        background-color: $organization_primary;
    }

    .MuiButton-contained:hover {
        background-color: $organization_primary_focus;
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        color: #fff;
        font-size: 0.875rem;
        font-family: 'Frutiger';
        font-weight: 500;
        letter-spacing: 1.5px;
        border-radius: 10px;
        padding: 15px 30px 15px 30px;
    }

    .MuiButton-contained.Mui-disabled {
        background: $organization_primary_disabled;
        cursor: not-allowed;

        span {
            color: white;
        }
    }

    .sc-bwzfXH.sc-EHOje.hkQVFp,
    .sc-bwzfXH.sc-htpNat.flDhnP,
    .sc-bwzfXH.sc-bxivhb.kINVDU,
    .sc-bwzfXH.sc-ifAKCX.gJwEBr {
        .sc-bZQynM.iepmoa {
            background: white;
            width: 5px;
            height: 5px;
        }
    }

    .gfLNMF {
        width: 10px;
        height: 10px;
    }
}
