@import 'src/_media-query.scss';
@import 'styles/constants/colors.scss';
@import 'styles/constants/_table.scss';

@import 'styles/constants/colors';
@import '../../media-query';

.history-proposes {
    display: inherit;
    height: 100%;
    padding: 100px 20px 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;

        &--title {
            font-size: 34px;
            font-weight: 500;
            color: #545454;
            margin: 0;

            &-refresh-icon {
                color: $organization_primary;
                cursor: pointer;
                margin-left: 10px;
            }
        }
    }
    &__sub-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;
        &--subtitle {
            font-size: 24px;
            font-weight: 500;
            color: #545454;
            border-bottom: 2px solid #545454;
            padding: 0 5px 4px;
            margin: 0;
            &-consignado {
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 153px;
                color: $organization_primary;
                font-weight: 500;
                border-bottom: 2px solid $organization_primary;
                padding: 0 5px 4px;
            }
            &-anticipation {
                font-size: 19px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 170px;
                color: #a5a8aa;
                border-bottom: 1px solid #a5a8aa;
                padding: 0 5px 4px;
            }
        }
    }

    &__table {
        width: 100%;
        overflow-x: auto;

        .page-container--table {
            th {
                &:nth-child(1) {
                    width: 14%;
                }
                &:nth-child(2) {
                    width: 10%;
                }
                &:nth-child(3) {
                    width: 17.5%;
                }
                &:nth-child(4) {
                    width: 14%;
                }
                &:nth-child(5) {
                    width: 11%;
                }
            }
            td {
                margin-right: 15px;
                overflow: hidden;
                text-overflow: ellipsis;
                &:nth-child(1) {
                    width: 35%;
                }
                &:nth-child(2) {
                    width: 40%;
                }
                &:nth-child(3) {
                    width: 57%;
                }
                &:nth-child(4) {
                    width: 40%;
                }
                &:nth-child(5) {
                    width: 45%;
                }
                &:nth-child(6) {
                    width: 50%;
                }
                &:nth-child(7) {
                    width: 25%;
                    .material {
                        font-size: 13px;
                        font-weight: 500;
                        color: #bac0c2;
                        cursor: pointer;
                        &:hover {
                            color: $organization_primary;
                        }
                    }
                }
                &:nth-child(8) {
                    width: 33%;
                    .material {
                        font-size: 13px;
                        font-weight: 500;
                        color: #bac0c2;
                        cursor: pointer;
                        &:hover {
                            color: #db6968;
                        }
                    }
                }
            }
            &-status {
                color: #000000;
                display: inline-flex;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: flex-start;
                height: 24px;
                font-weight: 500;
                border-radius: 5px;
                padding: 10px;
                font-family: 'Frutiger';

                &.HISTORY {
                    background-color: #6acff1;
                }

                &.UNKNOWN_MANAGER {
                    background-color: #f2c466;
                }

                &.MANAGER_REVIEW {
                    background-color: #f2c466;
                }

                &.HUMAN_RESOURCES_REVIEW {
                    background-color: #f2c466;
                }
                &.OPERATIONAL_REVIEW {
                    background-color: #f2c466;
                }
                &.NEED_CHANGE {
                    background-color: #f2c466;
                }
                &.INVALID_BANK_ACCOUNT {
                    background-color: #f2c466;
                }
                &.APPROVED {
                    background-color: #6acff1;
                }
                &.WAITING_SIGNATURE {
                    background-color: #6acff1;
                }
                &.SIGNED {
                    background-color: #B8D759;
                }
                &.CANCELLED {
                    background-color: #FF7978;;
                }
                &.RECUSED {
                    background-color: #FF7978;;
                }
            }

            @include responsive(s) {
                th {
                    &:nth-child(1) {
                        width: 280px;
                    }
                    &:nth-child(2) {
                        width: 250px;
                    }
                    &:nth-child(3) {
                        width: 300px;
                    }
                    &:nth-child(3) {
                        width: 300px;
                    }
                    &:nth-child(3) {
                        width: 300px;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 264px;
                    }
                    &:nth-child(2) {
                        width: 250px;
                    }
                    &:nth-child(3) {
                        width: 275px;
                    }
                    &:nth-child(4) {
                        width: 215px;
                    }
                    &:nth-child(5) {
                        width: 120px;
                    }
                    &:nth-child(6) {
                        width: 200px;
                    }
                    &:nth-child(7) {
                        width: 100px;
                    }
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        &-fields {
            display: flex;
        }

        &-empty-filter {
            width: 100%;
            height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
    }
}
