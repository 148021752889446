@import '../custom_organization/colors.scss';

$black: #000000;

$white_two: #ffffff;
$white_three: #f6f6f6;
$white: #f5f5f5;
$alabaster: #fafafa;

$light-periwinkle: #e1e2e6;
$cloudy_blue: #c3c7cb;
$light_periwinkle: #e1e2e6;
$ice_blue: #e8ebec;
$bluey_grey: #9199a1;
$greyish_brown: #4a4a4a;
$very_light_pink: #f2f2f2;
$very_light_pink_two: #e3e3e3;
$brown_grey: #7b7b7b;
$brown_grey_two: #aaaaaa;
$very_light_pink_three: #b9b9b9;
$brown_grey_three: #a4a4a4;
$scorpion: #5e5e5e;
$mercury: #e3e3e3;
$black_squeeze: #f4f8fb;

$link_water: #d4e5f0;
$cool_blue: #3aadd1;
$cool_blue_two: $organization_secundary_link;
$denim_blue: #39549e;
$dusky_blue: #486094;
$picton_blue: #51cdf3;
$madison: $organization_secondary;

$gross_green: #9ac91c;
$greenish_teal: #34c7a9;
$turquoise: #00bfa5;
$sick_green: #9bc731;
$sickly_green: #86b31a;
$soft_green: #6dbc70;

$piss_yellow: #d9cc18;
$yellowish: #f6ed71;
$sun_yellow: #e6da3c;

$pumpkin: #ef7d00;

$punch: #e04444;

$dark: #2d3e46;
