@import '../../media-query';
@import '../../styles/constants/colors.scss';
@import '../../styles/constants/fonts.scss';

.user-profile__items {
    font-family: Frutiger;

    .personal-data__items {
        padding-top: 110px;
        display: flex;
        justify-content: center;

        .col-md-9 {
            margin-left: -30px;
            padding-left: 78px;
            max-width: 65%;
        }

        &--back {
            height: 45px;
            border-bottom: solid 1px #e3e3e3;
            border-right: solid 1px #e3e3e3;
            border-left: solid 1px #e3e3e3;
            position: fixed;
            background: white;
            top: 70px;
            left: 0;
            right: 0;
            z-index: 10;
            overflow: hidden;
            display: flex;
            flex-wrap: nowrap;
            

            .container {
                padding: 0;
            }

            .row {
                flex-wrap: nowrap;
                margin: 0;
                justify-content: flex-end;
            }
            .license-name__items {
                &--cancel {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    font-weight: 500;
                    cursor: pointer;
                    height: 46px;
                    font-size: 0.75rem;
                    letter-spacing: 0.8px;
                    color: $brown_grey_three;
                    border-left: solid 1px #e3e3e3;
                    padding-left: 15px;
                    text-decoration: none;
                }
                &--save {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    font-weight: 500;
                    background: #30ab64;
                    color: white;
                    cursor: pointer;
                    height: 46px;
                    font-size: 0.75rem;
                }
                &--edit {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    font-weight: 500;
                    background: $organization_secundary_link;
                    color: white;
                    cursor: pointer;
                    height: 46px;
                    font-size: 0.75rem;
                }
            }
            @include responsive(s) {
                .col-md-2 {
                    padding: 0;
                }
            }
        }
        &--sidebar {
            &-title {
                font-size: 0.875rem;
                font-weight: bold;
                color: $dark;
                padding: 25px 0 15px;
            }
            &-subtitle {
                font-size: 0.875rem;
                font-weight: 300;
                color: $cool_blue_two;
                padding: 10px;
                cursor: pointer;
                border-bottom: solid 1px #e3e3e3;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .personal-data__items--sidebar-icon {
                    display: none;
                    width: 7px;
                    height: 7px;
                    position: relative;
                    border-top: solid 1px $organization_secondary;
                    border-left: solid 1px $organization_secondary;
                    transform: rotate(135deg);
                    cursor: pointer;
                }
            }
            &-subtitle.active {
                border-radius: 2px;
                background-color: rgba(50, 170, 207, 0.1);
                font-weight: 400;
                color: $dark;
                .personal-data__items--sidebar-icon {
                    display: flex;
                }
            }
        }

        &--title {
            font-size: 1.5rem;
            font-weight: 300;
            color: $organization_primary;
            border-bottom: solid 1px $organization_primary;
            margin-bottom: 8px;
            padding-bottom: 8px;

            @include responsive(s) {
                margin-bottom: 20px;
                align-items: flex-start;
            }
        }
        &--title-two {
            font-size: 14px;
            font-weight: bold;
            color: $dark;
        }
        &--text {
            font-family: Catamaran;
            height: 45px;
            border-bottom: solid 1px #e3e3e3;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &-first {
                font-size: 12px;
                color: #7b7b7b;
            }
            &-second {
                color: $dark;
                font-size: 1rem;
                margin-top: -5px;
                border: 0;
                background: transparent;
                font-family: Catamaran;
                width: 100%;
            }
        }
        &--input {
            font-family: Catamaran;
            height: 45px;
            margin-bottom: 20px;
        }
        &--loading {
            min-height: 80vh;
            .gfLNMF {
                .iepmoa {
                    background-color: $organization_primary_focus;
                }
            }
        }
        &--has-error {
            padding-top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100vh;
            flex-direction: column;
        }
        &__text {
            font-family: 'Catamaran', sans-serif;
            font-size: 1.25rem;
            color: $scorpion;
            text-align: center;
            line-height: 20px;
            margin-bottom: 30px;
        }
        .button__style--back .MuiButton-contained:hover,
        .button__style--back .MuiButton-contained {
            max-width: 220px;
            outline: 0;
        }

        @include responsive(s) {
            .col-md-9 {
                margin: 0;
                padding: 0;
                max-width: 100%;
            }
        }
    }
    .disabled {
        opacity: 0.7;
        cursor: not-allowed !important;
    }

    @include responsive(s) {

        .container > * {
            padding: 0;
            margin: 25px 0 50px;
        }
        
        .col-md-2, 
        .col-md-9 {
            width: 100%;
        }
    }
}
