@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';

.modal-company__company-selection--loading {
    .gfLNMF {
        .iepmoa {
            background-color: $organization_primary_focus;
        }
    }
}

.modal-company__company-selection--error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .company-selection__error-message {
        font-family: 'Catamaran', sans-serif;
        font-size: 1.25rem;
        color: $scorpion;
        text-align: center;
        line-height: 20px;
        margin-bottom: 30px;
    }
    .button__style--back .MuiButton-contained:hover,
    .button__style--back .MuiButton-contained {
        max-width: 220px;
    }
}

.modal-company__company-selection--empty-search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .company-selection__empty-message {
        font-family: 'Catamaran', sans-serif;
        font-size: 1.25rem;
        color: $scorpion;
        text-align: center;
        line-height: 20px;
        margin-bottom: 30px;
    }
    .button__style--back .MuiButton-contained:hover,
    .button__style--back .MuiButton-contained {
        max-width: 220px;
    }
}

.modal-company__company-selection--empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: 'Catamaran', sans-serif;
    font-size: 1.25rem;
    color: $scorpion;
    text-align: center;
    line-height: 20px;
}

.modal-company__company-selection {
    height: 80%;

    .makeStyles-root-228 {
        border: none;
        margin-top: 20px;
    }

    .MuiListItem-root.MuiListItem-gutters {
        display: flex;
        flex-direction: column;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 1px;
        padding-bottom: 4px;
    }

    .MuiList-root {
        scrollbar-width: thin;
        scrollbar-color: dark;
    }

    .MuiList-root::-webkit-scrollbar {
        border-radius: 5px;
        background-color: #ddd;
        width: 5px;
    }

    .MuiList-root::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #bbb;
    }

    .makeStyles-listSection-229 {
        padding: 0;
        margin-top: -6px;
    }

    .makeStyles-root-228 {
        max-height: 337px;
    }
}
