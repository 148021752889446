.button__style--edit-icon {
    .MuiButton-contained,
    .MuiButton-contained:hover {
        background: none;
        box-shadow: unset;
        border: none;
        outline: none;
        min-width: 20px;
        max-width: 100%;
        &.Mui-disabled {
            .button__style--edit-icon {
                content: url('../../../../images/edit-disabled.svg');
            }
        }
    }

    .MuiButton-label {
        width: auto;
    }
    .MuiButton-root {
        padding: 6px;
    }

    &--icon {
        content: url('../../../../images/edit-rest.svg');
        height: 20px;
        width: 20px;
        &:hover {
            content: url('../../../../images/edit-hover.svg');
        }
    }
}
