@import '../../../../../styles/constants/colors.scss';
@import '../../../../../media-query';
@import '../card-service.scss';

.card-no-active-propose__container {
    border: 1px solid #f5f5f5;
    margin: 0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: $card_service_height;
    border-radius: 4px;
    display: flex;
    font-family: 'Frutiger';

    @include responsive(s) {
        height: 240px;
    }

    @include responsive(ss) {
        height: 250px;
    }

    &--info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ffffff;
    }

    &--title {
        display: flex;
        justify-content: center;

        h3 {
            margin: 0;
        }
        &__icon {
            width: 80px;
            height: 80px;
            margin: 0px 122px 15px 125px;

            &.disabled {
                path {
                    fill: #bac0c2;
                }
            }
        }
    }

    &--no-propose {
        display: flex;
        width: 100%;;
        flex-wrap: wrap;
        
        h4 {
            text-align: center;
            width: 100%;
            font-family: 'Frutiger-light';
            color: #888888;
            font-size: 15px;
            margin-bottom: 0 !important;
        }

    }
}
