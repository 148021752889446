@import 'styles/constants/colors.scss';
@import 'styles/constants/fonts.scss';
@import 'styles/constants/_table.scss';

.toastify {
    &-container {
        padding: 0;
    }
    &-toast {
        background-color: transparent;
        box-shadow: none;
        margin: 0;
        display: flex;
        min-width: 400px;
        align-items: center;
        background: white;
        border-radius: 4px;
        border: 1px solid #d9cc18;
        position: relative;

        .button__style--close {
            position: absolute;
            min-width: 22px;
            top: 34%;
            right: 5%;
        }

        &.Toastify__toast--success {
            .Toastify__toast-body {
                &::before {
                    content: url('~images/positivo.svg');
                    width: 50px;
                    height: 34px;
                }
            }
        }
        &.Toastify__toast--error {
            .Toastify__toast-body {
                &::before {
                    content: url('~images/error.svg');
                    width: 60px;
                    height: 45px;
                    margin-left: 8px;
                }
            }
        }
        .Toastify__toast-body {
            position: relative;
            display: flex;
            align-items: center;
            font-family: 'Frutiger';
            font-size: 17px;
            color: #4a4a4a;
            text-align: left;
            width: 100%;
            margin-right: 36px;
            line-height: 21px;
        }
    }
}
