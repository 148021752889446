@import '../../../../../media-query';
@import '../../../../../styles/constants/colors.scss';
.container__scroll {
	border-bottom: solid 1px $very_light_pink_two;
	background: $white_two;
	z-index: 10;
	overflow: hidden;
	transition: height 300ms ease;
	padding: 44px 0 20px;

	&--small {
		height: 85px;
		.scroll--text {
			// height: 260px !important;
		}
		.scroll--text-title {
			font-size: 0.75rem !important;
		}
		.scroll--text-price {
			font-size: 1.625rem !important;
			margin-bottom: 20px;
		}
	}
	.scroll--text {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.scroll--text-title {
		display: flex;
		font-family: Frutiger;
		font-size: 0.875rem;
		color: $greyish_brown;
		font-weight: 300;
	}
	.scroll--text-price {
		display: flex;
		flex-direction: column;
		font-size: 2.125rem;
		margin: 20px 0px;
		font-family: Frutiger;
	}
	.scroll--text-button {
		border: none;
		background: $sick_green;
		width: 210px;
		height: 45px;
		border-radius: 10px;
		color: white;
		font-size: 0.875rem;
		font-family: Frutiger;
		letter-spacing: 1.5px;
		margin-bottom: 23px;
	}
	.scroll--text-button:hover {
		background: $sickly_green;
	}
	.scroll--text-value {
		flex-direction: row;
		display: flex;
		flex: 1;
		font-size: 0.875rem;
		font-family: Frutiger;
		color: $brown_grey_three;
		font-weight: 300;
		align-items: center;
		letter-spacing: 0.9px;
		margin-bottom: 20px;

		@include responsive(s) {
			flex-direction: column;
		}

		span {
			font-size: 1.0625rem;
			font-weight: 400;
			color: $greyish_brown;
		}
		.scroll--value-original {
			flex: 1;
			min-width: 150px;
			padding: 0 5px;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.scroll--value-itens {
			text-align: center;
			flex: 1;
			padding: 0 5px;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.scroll--value-separator {
			width: 1px;
			height: 37px;
			background: $very_light_pink_three;
			opacity: 0.7;
			@include responsive(s) {
				display: none;
			}
		}

		.taxes {
			padding-left: 10px;
			.scroll--value-itens {
				display: flex;
				flex-direction: row;
				align-content: center;
				align-self: center;
				justify-content: center;
				justify-self: center;
				justify-items: center;
				.scroll--value-img {
					content: url('../../../../../images/info.svg');
					padding-left: 3px;
					cursor: pointer;
				}
			}
		}
	}
	.scroll--text-terms {
		font-family: Frutiger;
		font-size: 0.8125rem;
		color: $greyish_brown;
		opacity: 0.7;
	}
}
