@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';
@import '../../../../styles/constants/fonts.scss';

.body__welcome {
    margin-top: 85px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Frutiger';
    @include responsive(s) {
        padding-left: 5px;
        padding-right: 5px;
    }

    .body__welcome--title {
        font-size: 1.625rem;
        text-align: center;
        margin-bottom: 26px;
        @include responsive(s) {
            margin-bottom: 28px;
            font-size: 1.375rem;
            line-height: 1.2;
        }
    }

    .body__welcome--text {
        font-size: 1.125rem;
        text-align: left;
        font-weight: 300;
        padding-bottom: 20px;
        line-height: 22px;
        margin: auto;
        @include responsive(s) {
            font-size: 1.1rem;
        }

        span {
            font-weight: 400;
        }
    }

    @include responsive(s) {
        margin-top: 25px;
    }
}

.container__button {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    margin: auto;

    @include responsive(s) {
        padding-bottom: 50px;
        padding-top: 20px;
    }
}
