@import '../../../media-query';
@import '../../../styles/constants/colors.scss';
@import '../../../styles/constants/fonts.scss';

.button__style--standard {
    .MuiButton-contained {
        background-color:  $white_two;
        border: solid 1px $madison;
        width: 300px;
    }

    .MuiButton-contained:hover {
        background-color:  $white_two;
        color: #fff;
        border: solid 1px $madison;
    }

    .MuiButton-label {
        justify-content: center;
        margin-top: 3px;
        white-space: nowrap;
    }

    &.small {
        .MuiButton-contained:hover,
        .MuiButton-contained {
            font-size: 0.75rem;
            font-weight: 600;
            letter-spacing: 1.29px;
            max-height: 36px;
            max-width: 150px;
        }
        .MuiButton-label {
            margin-top: 3px;
        }
    }

    &.medium {
        .MuiButton-contained:hover,
        .MuiButton-contained {
            font-size: 0.8125rem;
            letter-spacing: 1.29px;
            max-height: 40px;
            padding: 5px 25px;
        }
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        box-shadow: unset;
        color: $madison;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1.5px;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        max-width: 200px;
        max-height: 46px;
        padding: 12px 43px;
        outline: none;

        &.Mui-disabled {
            opacity: 0.6;
        }
    }

    .MuiButton-contained.Mui-disabled {
        background: $organization_primary;
        cursor: not-allowed;
        opacity: 0.6;
        
        span {
            color: white;
        }
    }

    .MuiButton-iconSizeMedium > *:first-child {
        margin-top: 1px;
    }
}
