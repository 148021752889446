.MuiBackdrop-root {
    display: flex;
    flex-direction: column;
    color: #ffff;
    background-color: #21252985 !important;
    z-index: 999!important;
}

.backdrop {
    font-family: 'Frutiger';

    &__loading-message {
        font-weight: 500;
        font-size: 22px;
        padding-bottom: 20px;
    }
}
