@import '../../../../styles/custom_organization/colors.scss';

.feedback-card {
    max-width: 230px;
    min-width: 230px;
    height: 141px;
    padding: 14px 20px 14px;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    background-color: #ffff;
    position: sticky;
    top: 22%;
    left: 15%;

    &__header {
        display: flex;

        &--icon {
            content: url(../../../../images/comment-edit-hover.svg);
            width: 20px;
            height: 20px;
            margin-right: 5px;
            margin-top: 4px;
        }

        &--warning-icon {
            content: url(../../../../images/warning.svg);
            width: 20px;
            height: 20px;
            margin-right: 5px;
            margin-top: 1px;
        }

        &--title {
            color: $organization_secondary;
            font-weight: 500;
            font-size: 18px;
        }

        &--content {
            font-family: 'Frutiger-light';
            font-size: 12px;
            font-weight: 300;
        }
    }
}
