@import '../../../media-query';
@import '../../../styles/constants/colors.scss';
@import '../../../styles/constants/fonts.scss';

.button__cancel {
    .MuiButton-contained {
        background-color: $white_two;
        border: solid 1px $very_light_pink_two;
        box-shadow: unset;
    }

    .MuiButton-contained:hover {
        background-color: $white_two;
        border: solid 1px $brown_grey;
        box-shadow: unset;

        .button--arrow {
            color: $brown_grey;
        }

        span {
            color: $brown_grey;
        }
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        width: 100%;
        max-width: 170px;
        min-width: 170px;
        color: $brown_grey_three;
        font-size: 0.875rem;
        font-family: 'Frutiger';
        font-weight: 400;
        letter-spacing: 1.5px;
        border-radius: 10px;
        min-height: 45px;
        max-height: 45px;
        @include responsive(s) {
            max-width: 130px;
            min-width: 130px;
        }
    }

    .button--arrow {
        color: $brown_grey_three;
        @include responsive(s) {
            transform: rotate(90deg);
        }
    }
}
