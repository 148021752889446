@import '../../styles/constants/colors.scss';

.base-input {
    width: 100%;
    position: relative;

    // Mui input's base
    .MuiInputBase,
    .MuiFormControl {
        &-root {
            border-radius: 6px;
            background: none !important;
            width: 100%;
        }
    }
    

    .MuiInputBase-input {
        height: 1.6em;
        font-size: 17px;
    }

    .MuiInputBase-root {
        padding: 0;
    }

    // Input's label styles
    label {
        display: none;

        &.Mui-focused {
            display: block !important;
            transform: translate(15px, 8px) scale(0.75) !important;
            font-size: 13px;
            z-index: 1;
            color: $bluey_grey;
        }
    }

    // Controls the input's placeholder when the label is not visible
    label[data-shrink='false'] + .MuiInputBase-formControl .MuiInputBase-input::placeholder {
        color: #9199a1;
        font-size: 15px;
        opacity: 1 !important;
    }

    // Input's styles
    input {
        border-radius: 6px;
        font-size: 16px;
        padding: 16px 15px 13px;

        &:focus::placeholder {
            opacity: 0;
        }

        &:not(:focus) {
            background-color: #f6f6f6;
            border: solid 1px #e8ebec;

            &::placeholder {
                opacity: 1 !important;
            }
        }

        &:focus {
            background-color: #ffffff;
            padding: 23px 15px 6px;
        }
    }

    // Focus and dirty control

    &.dirty,
    .Mui-focused {
        label {
            display: block !important;
            transform: translate(15px, 8px) scale(0.75) !important;
            font-size: 13px;
            z-index: 1;
            color: $bluey_grey;
        }
        

        input {
            background-color: #ffffff !important;
            border: solid 1px #aaaaaa !important;
            padding: 23px 15px 6px;
        }
        

    }
    
    // Remove the input's underline (Mui pattern)
    .MuiFilledInput-underline::before,
    .MuiFilledInput-underline::after {
        display: none;

        &.Mui-focused::after {
            display: block;
        }
    }

    // Input adornment
    .MuiInputAdornment-root {
        margin: 0;

        .MuiIcon-root {
            display: flex;
            align-items: center;
            justify-content: center;

            .search-icon {
                display: flex;
                width: 60%;
            }
        }
    }
    .MuiFilledInput-adornedEnd {
        span {
            margin-top: 2px;
        }
    }

    input:read-only {
        background-color: #f8f8f8 !important;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
    }
}

