@import '../../media-query';
@import '../../styles/constants/colors.scss';

.container__dashboard {
    padding-top: 70px;
    padding-bottom: 120px;
    min-height: 100vh;

    .dashboard--title {
        display: flex;
        flex-direction: column;
        margin: 30px 0px 20px 0px;
        
        span {
            font-weight: 500;
            font-size: 35px;
            color: #545454;
            font-family: 'Frutiger';
        }

        p {
            font-family: 'Frutiger-light';
            font-size: 18px;
            color:  #A5A8AA;
            font-weight: 300;
        }
    }

    .dashboard--subtitle {
        color: #545454;
        font-size: 24px;
        font-family: 'Frutiger';
        font-weight: 500;
        margin: 35px 0 16px 0;
    }

    .dashboard--link {
        font-family: 'Frutiger';
        font-size: 0.813rem;
        color: $cool_blue_two;
        text-align: right;
        text-decoration: none;

        a {
            color: $cool_blue_two;
        }

        .link--arrow {
            width: 17px;
        }
    }

    .dashboard--can-not-anticipate {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 60vh;
        font-family: 'Catamaran', sans-serif;
        font-size: 1.25rem;
        color: $scorpion;
        text-align: center;
        line-height: 20px;
        white-space: pre-wrap;
    }
}

.dashboard__container--error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $alabaster;
    border: solid $mercury 1px;
    border-radius: 8px;
    height: 100vh;

    @include responsive(s) {
        padding: 0px 15px;
    }

    .dashboard__error-message {
        font-family: 'Catamaran', sans-serif;
        font-size: 1.25rem;
        color: $scorpion;
        text-align: center;
        line-height: 20px;
        margin-bottom: 30px;
    }
    .button__style--back .MuiButton-contained:hover,
    .button__style--back .MuiButton-contained {
        max-width: 220px;
    }
}
