@import '../../styles/custom_organization/colors.scss';

.slider {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;

    &--label {
        font-family: 'Frutiger';
        font-size: 14px;
        line-height: 15px;
        color: #7b7b7b;
        text-align: center;
        letter-spacing: 0.541667px;
    }

    &--value {
        text-align: center;
        font-size: 27px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        color: $organization_primary;
    }

    &--error {
        color: red;
    }
}
