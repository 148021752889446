@import '../../../../../styles/constants/colors.scss';
@import '../../../../../media-query';
@import '../card-service.scss';

.card-payrollloan-service__container {
    border: 1px solid #f5f5f5;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: $card_service_height;
    border-radius: 4px;
    display: flex;
    font-family: 'Frutiger';

    @include responsive(s) {
        height: 240px;
    }

    @include responsive(ss) {
        height: 250px;
    }

    &--image {
        width: $card_service_image_width;
        height: 100%;
        border-radius: 4px;
        background-image: url('../../../../../images/payrollloan-service.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        mix-blend-mode: luminosity;

        @include responsive(s) {
            display: none;
        }
    }

    &--image-background {
        width: 150px;
        height: 100%;
        mix-blend-mode: multiply;
        background-color: #3f578b;
        border-radius: 4px;

        &.disabled {
            background-color: #a5a8aa;
        }

        @include responsive(s) {
            display: none;
        }
    }

    &--info {
        width: 100%;
        padding: 30px 20px 20px 27px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ffffff;

        h1 {
            font-size: 30px;
            color: #3f578b;
            font-weight: 600;
            font-family: 'Raleway';
            letter-spacing: 0.5px;

            @include responsive(m) {
                font-size: 28px;
            }

            @include responsive(sm) {
                font-size: 28px;
            }
        }

        h3 {
            font-size: 18px;
            font-weight: 500;
        }

        h4 {
            font-size: 15px;
            color: #a5a8aa;
            font-weight: 300;
            font-family: 'Frutiger-light';

            @include responsive(s) {
                font-size: 13px;
            }
        }

        &.disabled {
            background-color: #f5f5f5;
            color: #bac0c2;

            h1,
            h3,
            h4 {
                color: #bac0c2;
            }
        }
    }

    &--title {
        display: flex;
        justify-content: space-between;
        padding-right: 13px;

        &__icon {
            color: #3f578b;
            margin-right: 11px;

            &.disabled {
                path {
                    fill: #bac0c2;
                }
            }
        }
    }

    &--description {
        padding-bottom: 32px;
        max-width: 290px;
        white-space: pre-line;

        @media only screen and (max-width: 1265px) {
            padding-bottom: 12px;
        }
    }

    &--footer {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    &--credit-available {
        width: 230px;

        @media only screen and (max-width: 960px) {
            width: 65%;
        }

        @media only screen and (max-width: 538px) {
            width: 60%;
        }
    }

    &--button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
