@import '../../styles/constants/colors.scss';

.readonly__text-field {
    font-family: 'Frutiger';
    border-bottom: solid 1px $very_light_pink_two;
    margin: 10px 0 5px;
    padding-right: 15px;
    width: 100%;

    &-label {
        color: #999999;
        font-size: 13px;
        font-weight: 400;
        height: 14px;
        margin: 0;
        white-space: nowrap;

        &--center {
            color: #999999;
            font-size: 13px;
            font-weight: 400;
            height: 14px;
            margin: 0;
            white-space: nowrap;
            text-align: center;
        }
    }
    &-content {
        font-size: 16px;
        font-weight: 300;
        color: #2d3e46;
        margin: 4px 2px 7px;
        min-height: 24px;

        &--center {
            font-size: 16px;
            font-weight: 300;
            color: #2d3e46;
            margin: 4px 2px 7px;
            min-height: 24px;
            text-align: center;
        }
    }
}
