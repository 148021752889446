@import '../../../../../media-query';
@import '../../../../../styles/custom_organization/colors.scss';

.header__simulate-card-manager {
    text-align: left;
    margin-left: 150px;
    display: flex;
    flex-direction: column;

    @include responsive(s) {
        padding-left: 0px;
        margin-left: 0px;
        align-items: center;
    }

    &--title {
        font-size: 30px;
        line-height: 24px;
        padding: 20px 0px;
        font-weight: 500;
    }

    &--subtitle {
        font-size: 25px;
        line-height: 28px;
        color: $organization_secondary;
        padding: 5px 0px;
        font-weight: 300;
    }

    &--description {
        font-size: 15px;
        line-height: 16px;
        color: #a5a8aa;
        font-weight: 300;
    }
}

.card__simulate-card-manager {
    max-width: 410px;
    margin-left: 150px;
    margin-top: 15px;
    margin-bottom: 180px;
    text-align: center;

    @include responsive(s) {
        margin-left: 0px;
        margin: 20px auto;
    }
}
