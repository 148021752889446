@import '../../../../../media-query.scss';
@import '../../../../../styles/constants/colors.scss';
$table_border_radius: 8px;
.simulation-example {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding-top: 80px;
    padding-bottom: 40px;
    background-color: $white_two;
    transition: 1s;
    overflow: auto;
    .simulation-example__container--loading {
        min-height: 100vh;
        .gfLNMF {
            .iepmoa {
                background-color: $organization_primary_focus;
            }
        }
    }
    .simulation-example__container--error {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 90vh;
        .simulation-example__error-message {
            font-family: 'Catamaran', sans-serif;
            font-size: 1.25rem;
            color: $scorpion;
            text-align: center;
            line-height: 20px;
            margin-bottom: 30px;
        }
        .button__style--back .MuiButton-contained:hover,
        .button__style--back .MuiButton-contained {
            max-width: 220px;
        }
    }
    .simulation-example__container {
        width: 520px;
        .simulation-example__header {
            display: flex;
            flex-direction: column;
            align-items: center;
            .simulation-example__header--image {
                content: url('../../../../../images/money.svg');
                margin: auto;
            }
            .simulation-example__header--title {
                margin-top: 30px;
                font-family: Frutiger;
                font-size: 1.5rem;
                color: $greyish_brown;
            }
        }
        .simulation-example__cost {
            display: flex;
            flex-direction: column;
            align-items: center;
            .simulation-example__cost--title {
                font-family: Frutiger;
                font-size: 1.25rem;
                color: $greyish_brown;
                font-weight: 600;
                margin-top: 10px;
            }
            .simulation-example__fee-table {
                display: flex;
                flex-direction: column;
                flex: 1;
                border-width: 1px;
                border-style: solid;
                border-color: $link_water;
                border-radius: $table_border_radius;
                width: 100%;
                margin: 10px 0px;
                .simulation-example__fee-table--header {
                    display: flex;
                    flex-direction: row;
                    font-family: Frutiger;
                    color: $greyish_brown;
                    border-color: $link_water;
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    font-size: 0.94rem;
                    width: 100%;
                    background: $black_squeeze;
                    border-top-right-radius: $table_border_radius;
                    border-top-left-radius: $table_border_radius;
                    .simulation-example__fee-table--header-title {
                        display: flex;
                        flex: 1;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 25px;
                        text-align: center;
                        text-transform: uppercase;
                    }
                    .simulation-example__fee-table--header-value {
                        display: flex;
                        flex: 1;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        border-color: $link_water;
                        border-left-width: 1px;
                        border-left-style: solid;
                        align-items: center;
                        text-align: center;
                        padding: 10px 25px;
                        font-weight: 500;
                    }
                }
                .simulation-example__fee-table--content {
                    font-family: Frutiger;
                    color: $greyish_brown;
                    font-size: 0.96rem;
                    text-align: center;
                    padding: 25px 25px;
                    white-space: pre-wrap;
                }
            }
        }
        .simulation-example__example {
            display: flex;
            flex-direction: column;
            align-items: center;
            .simulation-example__example--title {
                font-family: Frutiger;
                font-size: 1.25rem;
                color: $greyish_brown;
                font-weight: 600;
                margin-top: 25px;
            }
            .simulation-example__example-table {
                display: flex;
                flex-direction: column;
                flex: 1;
                width: 100%;
                margin: 15px 0px;
                .simulation-example__example-table--header {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    border-style: solid;
                    border-color: $link_water;
                    border-width: 1px;
                    border-bottom-width: 0px;
                    border-top-left-radius: $table_border_radius;
                    border-top-right-radius: $table_border_radius;
                    width: 100%;
                    padding: 10px 25px;
                    font-family: Frutiger;
                    color: $greyish_brown;
                    font-size: 0.92rem;
                    background: $black_squeeze;
                    span {
                        font-size: 1.4rem;
                    }
                }
                .simulation-example__example-table--content {
                    border-style: solid;
                    border-color: $link_water;
                    border-width: 0px;
                    border-left-width: 1px;
                    border-right-width: 1px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    .simulation-example__example-table--content-row {
                        display: flex;
                        flex-direction: row;
                        flex: 1;
                        width: 100%;
                        border-color: $link_water;
                        border-width: 0px;
                        border-top-style: solid;
                        border-top-width: 1px;
                        .simulation-example__example-table--content-text {
                            display: flex;
                            justify-content: center;
                            align-items: flex-start;
                            flex-direction: column;
                            flex: 1;
                            width: 100%;
                            font-family: Frutiger;
                            color: $greyish_brown;
                            font-size: 0.92rem;
                            font-weight: 500;
                            padding: 10px 25px;
                            padding-left: 40px;
                            span {
                                text-transform: uppercase;
                                font-weight: 400;
                            }
                        }
                        .simulation-example__example-table--content-value {
                            display: flex;
                            justify-content: flex-end;
                            flex: 1;
                            width: 100%;
                            font-family: Frutiger;
                            color: $greyish_brown;
                            font-size: 1.15rem;
                            text-align: center;
                            border-color: $link_water;
                            border-width: 0px;
                            border-left-style: solid;
                            border-left-width: 1px;
                            padding: 10px 25px;
                            padding-right: 50px;
                        }
                    }
                }
                .simulation-example__example-table--footer {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    background: $madison;
                    border-style: solid;
                    border-color: $madison;
                    width: 100%;
                    border-width: 1px;
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    padding: 15px 25px;
                    font-family: Frutiger;
                    color: $white_two;
                    font-size: 0.92rem;
                    text-transform: uppercase;
                    span {
                        color: $sick_green;
                        font-size: 1.9rem;
                    }
                }
            }
        }
    }
    .simulation-example__close {
        content: url(../../../../../images/fechar-2.svg);
        cursor: pointer;
        position: absolute;
        top: 30px;
        right: 30px;
    }
}