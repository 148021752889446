@import '../../../../styles/custom_organization/colors.scss';


.page-header--menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 74px;
    width: 100%;
  
    a,
    span {
      cursor: pointer;
      user-select: none;
    }
  
    &-list {
      display: inline-flex;
      align-items: center;
      margin: 0;
      padding: 0;
      height: 100%;
  
      &-item {
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        height: 60%;
        white-space: nowrap;
        margin: 0 5px;
        position: relative;
  
        &:hover {
          background-color: $organization_menu_hover;
        }
  
        &.active {
          background: $organization_menu_selected;
  
          span,
          svg {
            color: white;
          }
        }
  
        &-button {
          border-radius: 5px;
          display: flex;
          justify-content: center;
          height: 100%;
          width: max-content;
          padding: 12px 20px;
  
          &:hover {
            text-decoration: none;
            cursor: pointer;
          }
  
          &.active {
            background: $organization_menu_selected;
  
            span,
            svg {
              color: white;
              font-weight: 400;
            }
          }
  
          > span {
            font-family: 'Frutiger';
            color: $organization_secundary_link;
            font-size: 14px;
            font-weight: normal;
          }
  
          .fa-user-circle,
          .fa-user-lock {
            color: $organization_secundary_link;
            margin-right: 9px;
          }
  
          .fa-user-circle {
            font-size: 22px;
          }
  
          .fa-user-lock {
            font-size: 16px;
            margin-top: 2px;
          }
        }
      }
    }
  }