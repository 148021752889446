@import '../../../../../../../styles/constants/colors.scss';


.select-propose-employee-account {
    display: flex;

    &__card {
        border: 1px solid #bac0c2;
        border-radius: 6px;
        width: 330px;
        display: flex;
        padding: 15px 20px;
        margin: 10px 0px;

        &:hover {
            border: 1px solid $organization_primary;
            cursor: pointer;
        }

        &.active {
            border: 1px solid $organization_primary;
            cursor: default;
        }

        &-item {
            display: flex;
            flex-direction: column;
            height: 52px;
            justify-content: center;
        }

        &-bank {
            color: #a5a8aa;
            font-size: 15px;
        }

        &-account {
            font-size: 16px;
        }

        &-checkbox {
            border: 1px solid #a5a8aa;
            width: 25px;
            height: 25px;
            border-radius: 100%;
            margin-right: 20px;
            position: relative;
        }

        &-checkbox-active {
            width: 15px;
            height: 15px;
            border-radius: 100%;
            background-color: $organization_primary;
            position: absolute;
            top: 4px;
            left: 4px;
        }
    }
}
