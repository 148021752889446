@import '../../../../../../../styles/constants/colors.scss';
@import '../../../../../../../media-query';

.card__propose-employee-summary {
    width: 540px;
    border: 1px solid rgba(138, 177, 233, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    padding: 20px;
    margin: 15px 25px;

    @include responsive(s) {
        width: 100%;
        margin: 20px 0px;
    }

    &.disabled {
        cursor: default;
        background-color: #f5f5f5
    }

    &--header {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        &-title {
            color: $organization_secondary;
            font-size: 18px;
            line-height: 21px;
        }
    }

    &--document {
        margin: 15px 0px;
        font-family: Frutiger;

        &__file {
            cursor: pointer;
            color: $organization_secundary_link;

            &--placeholder {
                border-bottom: 1px solid $organization_secundary_link;
                font-size: 15px;
            }

            &--selected {
                width: 100%;
                background-color: #f5f5f5;
                border-radius: 6px;
                color: $organization_secundary_link;
                font-size: 13px;
                padding: 8px;
                margin: 5px 0px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                position: relative;
                padding-right: 30px;

                &-value {
                    padding: 0px 10px;
                    cursor: pointer;

                    &:hover {
                        color: $organization_primary_focus;
                    }
                }

                &-file-icon {
                    transform: rotate(220deg);
                }

                &-button-cancel {
                    position: absolute;
                    right: 5px;
                    color: #d8d8d8;
                    cursor: pointer;

                    &:hover {
                        color: #ff4d6d;
                    }
                }
            }
        }
    }
}
