@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';
@import '../../../../styles/constants/fonts.scss';

.profile-editing__items {
    .container {
        & > * {
            margin-top: -90px;
            padding-top: 120px;
        }
        .personal-data__items--loading {
            min-height: 90vh;
            position: absolute;
            top: 0;
            width: 87%;
            background-color: rgba(255, 255, 255, 0.6);
        }
    }
    &--fields {
        margin-top: 30px;

        & > div {
            margin-bottom: 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            & > .number__field,
            & > .password__style--field {
                width: 100%;
            }
        }
        &-block {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0 16px;

            @include responsive(s) {
                max-width: 100%;
                width: 100%;
            }
        }
        &-card {
            background-color: #f9f9f9;
            border-radius: 4px;
            border: 0;
            max-width: fit-content;
            padding: 10px;
            font-size: 0.625rem;
            font-weight: 400;
            width: 100%;
            max-width: 100%;
            justify-content: center;

            &-text {
                color: $greyish_brown;
                margin-bottom: 5px;

                @include responsive(s) {
                    display: none;
                }
            }

            .password__strength--container {
                width: auto;
            }
            .password__strength--container .password__strength {
                width: 20%;
            }
        }

        .MuiCard-root {
            width: 157px;
            padding: 10px 10px 10px 10px;
            background: #f9f9f9;
            box-shadow: none;
            @include responsive(s) {
                width: 210px;
                padding: 1px 13px 1px 13px;
            }
        }

        @include responsive(s) {
            .MuiGrid-item {
                width: 100%;
                max-width: 100%;
            }
        }
    }

    &--message {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;

        &-container {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
        }
        &-card {
            margin-bottom: 60px;
            margin-top: 30px;
            height: 60px;
            border-radius: 4px;
            background-color: $white_two;
            box-shadow: 0 2px 4px 0 $light-periwinkle;
            border: solid 1px #d9cc18;
            display: flex;
            position: fixed;
            bottom: 0;
            padding: 0 !important;
            width: 100%;
            max-width: 610px;
            z-index: 2;
        }
        &-block {
            display: flex;
            align-items: center;
            height: 100%;
            max-width: 100%;
            width: 100%;
            & > * {
                display: flex;
                justify-content: space-around;
            }
            &-positive {
                content: url(../../../../images/positivo.svg);
                width: 41px;
                height: 41px;
                margin: 0 2% 0 5%;
            }
            &-close {
                content: url(../../../../images/fechar.svg);
                width: 24px;
                height: 24px;
                margin-right: 2.5%;
                cursor: pointer;
            }
            &-text {
                font-size: 1.3rem;
                line-height: 1.3rem;
                color: $greyish_brown;
                padding: 0 5% 0 2.5%;
                display: inline;
                :last-child {
                    font-weight: 500;
                }
            }
        }
    }

    // Inputs

    .text__field.MuiFormControl-root .MuiFormHelperText-root.MuiFormHelperText-contained {
        padding: 5px 15px;
    }
    .MuiInputBase-root {
        padding: 0;
    }
    .MuiFormControl-root {
        .MuiInputBase-root {
            & input[value='']:not(:focus) {
                background-color: $white_three;
                border-radius: 6px;
                .MuiFilledInput-root {
                    border: 0;
                }
            }
        }
    }
    .MuiFilledInput-root.Mui-disabled {
        background-color: $white_two;
        color: $greyish_brown;
        & .MuiInputAdornment-positionEnd {
            display: flex;
        }
    }
    .password__strength--container .password__strength {
        width: 20%;
    }

    @include responsive(s) {

        .MuiGrid-root {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 5px 0;
        }
    }
}
