@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';

.employee-borrower--container--step {
    flex-direction: column;
    width: 200px;
    margin-left: 6px;
    margin-bottom: 180px;
    position: fixed;

    &.detail {
        .employee-borrower--container--step {
            &-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: solid 1px #e3e3e3;
                border-radius: 2px;
                color: $organization_secundary_link;
                cursor: pointer;
                font-size: 14px;
                font-weight: 300;
                height: 43px;
                padding: 10px;
                width: 100%;
                white-space: nowrap;

                &:first-child {
                    border-top: solid 1px #e3e3e3;
                }

                &.active {
                    background-color: #ebf7fa;
                    color: #2d3e46;
                    font-weight: normal;

                    .employee-borrower--container--step-icon {
                        content: url(../../../../images/arrow.svg);
                        transform: rotate(270deg);
                        width: 15px;
                    }
                }
            }
        }
    }

    &.edit {

        .employee-borrower--container--step {
            &-line {
                cursor: default;
                display: flex;
                align-items: center;

                &-label {
                    white-space: nowrap;
                    font-size: 11px;
                    font-weight: normal;
                    letter-spacing: 0.46px;
                    text-align: left;
                    color: $organization_primary;
                    text-transform: uppercase;
                    line-height: 21px;
                    padding: 0;
                }

                &-label-small {
                    white-space: nowrap;
                    font-size: 9px;
                    font-weight: normal;
                    letter-spacing: 0.46px;
                    text-align: left;
                    color: $organization_primary;
                    text-transform: uppercase;
                    line-height: 21px;
                    padding: 0;
                }

                .employee-borrower--container--step-icon {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    margin-right: 15px;
                    width: 21px;
                    position: relative;

                    &::after {
                        content: '';
                        width: 1px;
                        background-color: #b9b9b9;
                        height: 48px;
                        position: absolute;
                        left: 12px;
                        top: 100%;
                        z-index: 0;
                    }
                }
            }

            &-item {
                &.disabled {
                    .employee-borrower--container--step-line-label-small,
                    .employee-borrower--container--step-line-label {
                        color: #b9b9b9;
                    }
    
                    .employee-borrower--container--step-icon {
                        &-icon {
                            border-radius: 100%;
                            background: #b9b9b9;
                            margin-left: 4px;
                            height: 10px;
                            width: 10px;
                            display: flex;
                            z-index: 1;
                        }

                        &-small-icon {
                            border-radius: 100%;
                            background: #b9b9b9;
                            margin-left: 4px;
                            height: 7px;
                            width: 7px;
                            display: flex;
                            z-index: 1;
                        }
                    }
                }

                &.active {
                    .employee-borrower--container--step-conector {
                        border-color: $organization_primary;
                    }
                    .employee-borrower--container--step-line-label-small,
                    .employee-borrower--container--step-item-label {
                        color: $organization_primary;
                    }
                    .employee-borrower--container--step-icon {
                        &-icon {
                            background: #fff;
                            content: url(../../../../images/step-active.svg);
                            height: 21px;
                            width: 21px;
                            margin-left: 4px;
                            z-index: 1;
                        }

                        &-small-icon {
                            background-color: $organization_primary;
                            border-radius: 100%;
                            margin-left: 4px;
                            height: 7px;
                            width: 7px;
                            display: flex;
                        }
                    }
                }

                &.passed {
                    .employee-borrower--container--step-line-label,
                    .employee-borrower--container--step-line-label {
                        color: $organization_primary;
                    }
                    .employee-borrower--container--step-icon-icon {
                        background: #fff;
                        content: url(../../../../images/step-active.svg);
                        height: 21px;
                        width: 21px;
                        margin-left: 4px;
                        z-index: 1;
                    }
                    .employee-borrower--container--step-icon-small-icon {
                        background-color: $organization_primary;
                    }
                    .employee-borrower--container--step-icon::after {
                        background-color: $organization_primary;
                    }
                }

                &.completed {
                    .employee-borrower--container--step-line-label,
                    .employee-borrower--container--step-line-label {
                        color: $organization_primary;
                    }
                    .employee-borrower--container--step-icon {
                        &-icon {
                            background: #fff;
                            content: url(../../../../images/step-complete.svg);
                            margin-left: 4px;
                            width: 21px;
                            z-index: 1;
                        }

                        &-small-icon {
                            background-color: $organization_primary;
                            border-radius: 100%;
                            margin-left: 4px;
                            height: 7px;
                            width: 7px;
                            display: flex;
                        }
                    }
                }

                &:first-child {
                    .employee-borrower--container--step-icon::after {
                        bottom: 50%;
                    }
                }
                &:last-child {
                    .employee-borrower--container--step-icon::after {
                        height: 0;
                    }
                }
            }

            &-conector {
                display: block;
                height: 34px;
                margin-left: 10px;
            }

            &-small-conector {
                display: block;
                height: 20px;
                margin-left: 10px;
            }
        }
    }
}

.rectangle-step {
  height: 4px;
  width: 23px;
  flex: 1 0 0;
  background: #E8E8E8;
  &:first-child {
    display: none;                  
  }
  &.active,
  &.passed {
    background-color: $organization_primary;
  }
}

.rectangle-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  margin: auto;
  span {
    color: var(--gray-gray-1, #888);
    font-family: Frutiger 55;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 11px */
  }
}

.rectangle-bar {
  display: flex;
  flex: 1;
  padding: 0px 20px;
}
