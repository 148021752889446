@import '../../../../styles/constants/colors.scss';
@import '../../../../styles/constants/fonts.scss';
@import '../../../../media-query';

.logo--container {
  @media only screen and (max-width: 767px) {
    background-color: $organization_primary;
    height: 100px;
  }
}

.row--container {
  min-height: 100vh;
  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}

.col--inputs {
  margin: auto;
  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
  }
}

.login--logo {
	content: url('../../../../images/custom_organization/white_logo.svg');
	position: absolute;
	top: 40px;
	left: 50px;
	max-width: 150px;
	z-index: 1;
}

.login--title {
	color: $greyish_brown;
	font-size: 0.875rem;
	font-family: 'Frutiger';
	font-weight: 500;

	p {
		margin: 0px;
	}
}

.login--link {
	text-decoration: underline;
	font-size: 0.8125rem;
	font-family: 'Frutiger';
	text-align: right;

	a {
		color: #707070;
	}
}

.login--root {
	overflow-x: hidden;
}

.login--container {
	overflow-x: hidden;

  @media only screen and (max-width: 768px) {
    margin: 16px;
  }
	.login--buttons {
		display: flex;
		justify-content: space-between;

		.button__style--action {
			.MuiButton-contained:hover,
			.MuiButton-contained {
				width: 165px;
				height: 55px;
				font-family: 'Frutiger';
				font-weight: 500;
				font-size: 0.875rem;
			}
		}
	}
}
