.read-only-field__propose-employee-summary {
    border-bottom: 1px solid #e3e3e3;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &--label {
        color: #999999;
        font-size: 12px;
    }

    &--value {
        color: #2d3e46;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }
}
