@import '../../media-query';
@import '../../styles/constants/colors.scss';

.context-ribbon {
    background-color: #fafafa;
    height: 45px;
    border-bottom: solid 1px $very_light_pink_two;
    position: fixed;
    background: $white_two;
    top: 74px;
    left: 0;
    right: 0;
    z-index: 10;
    overflow: hidden;

    .context-ribbon__back {
        display: flex;
        align-items: center;
        height: 45px;
        margin-left: 6px;

        .context-ribbon__back-icon {
            content: url('./../../images/back-simulation.svg');
            margin-right: 10px;
            cursor: pointer;
        }

        span {
            font-size: 0.875rem;
            font-weight: 300;
            font-family: Frutiger;
            color: $cool_blue;
            cursor: pointer;
        }
    }
}
