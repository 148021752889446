@import 'styles/constants/colors';
@import '../../media-query';

.page-container--table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 10px 0;
    width: 100%;

    tbody {
        position: relative;
    }
    &-head {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: solid 2px $cool_blue_two;
        color: $cool_blue_two;
        font-size: 0.75rem;
        height: 30px;
        &-title {
            cursor: pointer;
            display: flex;
            font-family: 'Frutiger';
            letter-spacing: 1px;
            text-transform: uppercase;
            font-size: 11px;
            font-weight: bold;
         
            .page-container--table-head-arrow {
                margin-left: 5px;
            }
        }
        &-search {
            content: url('~images/blue-search.svg');
            cursor: pointer;
            height: 36px;
            margin-left: 7px;
            padding: 8px;
            width: 36px;
            opacity: 0.8;
            &:hover {
                opacity: 1;
            }
        }
    }
    &-cell {
        align-items: center;
        border-bottom: solid 1px #d0d0d0;
        color: #4A4A4A;
        display: flex;
        font-size: 15px;
        font-weight: 300;
        height: 46px;
        line-height: 2.43;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;

        &:hover {
            background-color: #ffffff;
        }
        &-title {
            width: 100%;
            display: flex;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: 'Frutiger-light';
        }
        &-text {
            display: flex;
            justify-content: center;
            width: 100% !important;
        }
        &-icon {
            color: gray;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            height: 65%;
            width: 5%;

            @include responsive(sm) {
                width: 7%;
            }

            @include responsive(s) {
                width: 13%;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #d8d8d8;
                font-size: 1.1rem;
                height: 100%;
                margin: 0 5px;
                width: 100%;
                &:hover {
                    color: #9e9e9e;
                }
            }
        }
    }

    th,
    td {
        &:nth-child(1) {
            padding-left: 20px;
        }
        span {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
