@import '../../../../media-query';
@import '../../../../styles/custom_organization/colors.scss';

.slide__employee-borrower {
    width: 100%;
    box-shadow: 0px -2px 3px #e1e2e6;
    background: #ffff;
    position: fixed;
    bottom: 51px;
    left: 0;
    right: 0;
    display: flex;
    z-index: 10;
    justify-content: center;
    height: 75px;
    align-items: center;
    font-family: 'Frutiger';

    @include responsive(s) {
        height: 155px;
    }

    &--container {
      height: 128px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: 767px) {
          flex-direction: column;
          gap: 16px;
        }
    }

    &--item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:nth-child(2){
            margin-left: 22px;
        }
        
        &__title {
            font-size: 13px;
            color: #5C5C5C;

          @media only screen and (max-width: 767px) {
                  text-align: center;
          }
            
            @include responsive(s) {
                width: 120px;
            }

            .MuiSvgIcon-root {
                font-size: 1.1rem;
                color: $organization_primary
            }
        }

        &__value {
            font-size: 18px;
            color: $organization_secondary;
            @media only screen and (max-width: 767px) {
                text-align: center;
            }

            strong {
                font-weight: 500;
            }
        }
    }

    &--buttons {
        display: flex;
        align-items: center;

    }
}
