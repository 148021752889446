@import '../../../../../media-query';
@import '../../../../../styles/constants/colors.scss';

.header__propose-employee-step {
    text-align: left;
    margin-left: 120px;
    display: flex;
    flex-direction: column;

    @include responsive(s) {
        padding-left: 0px;
        margin-left: 0px;
        align-items: center;
    }

    &--title {
        font-size: 30px;
        line-height: 24px;
        padding: 20px 0px;
        font-weight: 500;
    }

    &--subtitle {
        font-size: 25px;
        line-height: 28px;
        color: $organization_secondary;
        padding: 5px 0px;
        font-weight: 300;
    }

    &--description {
        font-size: 15px;
        line-height: 16px;
        color: #a5a8aa;
        font-weight: 300;
    }
}

.form__propose-employee-step {
    display: flex;
    margin-left: 120px;
    flex-direction: column;
    max-width: 350px;
    margin-top: 10px;
    margin-bottom: 25px;

    @include responsive(s) {
        margin-left: 0px;
        width: 100%;
        margin-bottom: 150px;
    }

    &--input {
        padding: 10px 0px;
    }

    &--box-info {
        border: 1px solid $organization_primary;
        border-radius: 10px;
        padding: 20px;
        margin: 10px 0px;
        color: $organization_primary;
        font-size: 15px;
    }

    &--card {
        max-width: 410px;
        display: flex;
        flex-direction: column;
        margin-left: 120px;
        margin-top: 20px;
        margin-bottom: 25px;

        @include responsive(s) {
          padding-left: 0px;
          margin-left: 0px;
          align-items: center;
        }

        &__input {
            padding: 6px;
        }

        &__dashed-button {
            border: 1px dashed $organization_primary;
            border-radius: 6px;
            width: 330px;
            padding: 12px 20px;
            margin: 10px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $organization_primary;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.28571px;
            cursor: pointer;
        }
    }
}

.container__propose-employee-step {
    height: 90%;
    padding: 12px 0px;
    margin-bottom: 120px;
    margin-left: 67px;

    @include responsive(s) {
        height: 100%;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 180px;
    }
}

.loading-stage{
    display: flex;
    justify-content: center;
    align-items: center;
}

.summary-container{
    display: flex;
    @include responsive(s) {
        height: 100%;
        max-width: 100%;
        margin-left: 0px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: start;
        padding: 20px;
    }
}