.modal-message {
    z-index: 2;

    &--content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 14px 32px 30px;
        border-radius: 6px;
        background-color: #ffffff;
    }
    &--header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 8px;
    }
    &--title {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.25;
        text-align: center;
        color: #4A4A4A;
        margin-bottom: 16px;
        padding: 0 20px;
        font-family: 'Frutiger-55';
    }
    &--text {
        width: 100%;
        font-size: 17px;
        font-weight: 300;
        text-align: center;
        color: #545454;
        padding: 0 20px;
        margin-bottom: 0;
        font-family: 'Frutiger-light';
    }
    &--actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        > :nth-child(2) {
            margin-left: 25px;
        }
    }
}

.modal-content {
    bottom: 50px;
    max-width: 100%;
}
