@import '../../../../../media-query';
@import '../../../../../styles/constants/colors.scss';
@import '../../../../../styles/constants/fonts.scss';

.button__request {
    .MuiButton-contained {
        background-color: $white_two;
        border: solid 1px $organization_primary;
        box-shadow: unset;
    }

    .MuiButton-contained:hover {
        box-shadow: unset;
        background-color: $organization_primary;
        color: $white_two;

        .button--arrow {
            color: $white_two;
        }

        span {
            color: $white_two;
        }
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        max-width: 180px;
        min-width: 160px;
        color: $organization_primary;
        font-size: 0.875rem;
        font-family: 'Frutiger';
        font-weight: 400;
        letter-spacing: 1.5px;
        border-radius: 10px;
        min-height: 48px;
        max-height: 48px;
    }

    .button--arrow {
        color: $organization_primary;
        @include responsive(s) {
            transform: rotate(90deg);
        }
    }

    .MuiButton-contained.Mui-disabled {
        border: solid 1px #BAC0C2;
        background-color: #F5F5F5;
    }
}
