@import 'styles/constants/colors.scss';
@import 'styles/constants/_table.scss';

.history-proposes-modal-filter--container {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    width: 420px !important;
}

.history-proposes-modal-filter--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;

    &-title {
        font-size: 20px;
        font-weight: bold;
        color: #292a2a;
        letter-spacing: 0.3px;
        line-height: 1.2;
        margin: 0;
    }
}

.history-proposes-modal-filter--buttons {
    &-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        > :nth-child(2) {
            margin-left: 25px;
        }
    }
}





