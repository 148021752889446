@import '../../../media-query';
@import '../../../styles/constants/colors.scss';

.header-awareness__container--loading {
    min-height: 100vh;
    position: relative!important;
    left: -20px!important;

    .gfLNMF {
        .iepmoa {
            background-color: $organization_primary_focus;
        }
    }
}

.header-awareness__container--has-error {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;

    .header-awareness__text {
        font-family: 'Catamaran', sans-serif;
        font-size: 1.25rem;
        color: $scorpion;
        text-align: center;
        line-height: 20px;
        margin-bottom: 30px;
    }

    .button__style--back .MuiButton-contained:hover,
    .button__style--back .MuiButton-contained {
        max-width: 220px;
    }
}
