@import '../../../../media-query';
@import '../../../../styles/constants/colors.scss';
@import '../../../../styles/constants/fonts.scss';

.body__identification {
    margin-top: 67px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Frutiger';
    width: 100%;
    @include responsive(s) {
        margin-top: 10px;
    }

    .MuiFormControl-marginNormal {
        margin-top: 0;
        margin-bottom: 6px;
    }

    .MuiPaper-elevation1 {
        border: solid 1px $light_periwinkle;
        border-bottom: 8px;
    }

    .MuiCard-root {
        @include responsive(s) {
            z-index: 1;
            margin-bottom: 20px;
        }

        .button--close {
            top: 5px;
            right: 20px;
            @include responsive(s) {
                display: none;
            }
        }
    }

    &--email {
        color: $cool_blue_two;
        font-size: 0.813rem;
        text-decoration: underline;
        font-weight: 400;
        letter-spacing: 0.5px;
        margin-bottom: 30px;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        span {
            cursor: pointer;
            margin-bottom: 10px;
        }
        @include responsive(s) {
            margin-top: 2px;
            text-align: end;
        }
    }

    &--title {
        margin: auto;
        font-size: 1.375rem;
        text-align: center;
        margin-bottom: 51px;
        line-height: 1.3;
        @include responsive(s) {
            padding-right: 15px;
            padding-left: 15px;
            font-size: 1.1rem;
            margin-bottom: 20px;
        }

        span {
            font-weight: 500;
        }
    }

    &--components {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        width: 100%;
        @include responsive(s) {
            margin-bottom: 18px;
        }
    }
}

.container__button--second {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    @include responsive(s) {
        margin-top: 0px;
        margin-bottom: 12px;
    }

    .button--arrow {
        @include responsive(s) {
            transform: rotate(360deg);
            padding-right: 0;
            padding-top: 5px;
        }
    }

    .MuiInputBase-input {
        font-family: 'Frutiger';
    }
}
