/*

 Custom colors for Giro.Tech organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

$organization_primary: #000;
$organization_primary_focus: #4E4E4E;
$organization_primary_disabled:  #4E4E4E;

$organization_program_default: #000;

$organization_secondary: #2A2A2A;
$organization_secondary_focus: #784af4;

$organization_secondary_transparent: #030b1db3;

$organization_secundary_border: #486094;

$organization_secundary_link: #BABABA;
$organization_secundary_link_hover: #51cdf3;

$organization_secundary_button: #000;
$organization_secundary_button_focus: #4E4E4E;

$organization_menu_selected: #4E4E4E;
$organization_menu_hover: #757474fd;
